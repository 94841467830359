import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { ClipLoader } from "react-spinners";
import { FaTrashAlt } from "react-icons/fa";
import { ErrorMessage, Formik } from "formik";

import Form from "./styles";
import InputStyled from "../../../../components/styles/InputStyled";
import Table from "../../../../components/styles/Table";
import ButtonDefault from "../../../../components/styles/buttons";
import requests from "../../../../services/requests";
import { colors } from "../../../../assets/colors";

export default function ModalForm({ client, onSuccess }) {
    const [source, setSource] = useState();
    const [percent, setPercent] = useState({});
    const [progress, setProgress] = useState();

    const removeTempFile = ({ values, key, setValues, setErrors }) => {
        const form = document.getElementById("form");
        form.reset();
        setErrors({});

        delete values.files[key];

        setValues({ ...values });
        setPercent({});
    };

    useEffect(() => {
        const cancel = Axios.CancelToken;
        setSource(cancel.source);
        return () => {
            onSuccess();
            return source?.cancel();
        };
    }, []);

    useEffect(() => {
        if (progress) {
            setPercent({ ...percent, ...progress });
        }
    }, [progress]);

    return (
        <Formik
            initialValues={{
                files: {},
            }}
            onSubmit={(values, { setFieldError, setSubmitting }) => {
                const { files } = values;

                const form = document.getElementById("form");
                form.reset();

                if (!Object.keys(files).length) {
                    toast.error("Clique e escolha um arquivo ou arraste.");
                }

                Object.entries(files).forEach(([key, file]) => {
                    if (client === null) {
                        toast.error("Você precisa selecionar um cliente.");
                    }

                    if (client !== null) {
                        const config = {
                            onUploadProgress: function (progressEvent) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress({ [key]: percentCompleted });
                            },
                            cancelToken: source.token,
                        };

                        const docs = new FormData();
                        docs.append("estoque_file_upload[file]", file);
                        docs.append("estoque_file_upload[client]", client);

                        requests
                            .savePhotosUploads(client, docs, config)
                            .then(() => {
                                toast.success(
                                    <div>
                                        {file.name} <br /> enviado com sucesso
                                    </div>
                                );
                                delete values.files[key];
                            })
                            .catch(() => {
                                toast.error(
                                    <div>
                                        {file.name} <br /> falhou, tente novamente.
                                    </div>
                                );
                            })
                            .finally(() => setSubmitting(false));
                    }
                });

                setSubmitting(false);
            }}
        >
            {({ setValues, handleSubmit, isSubmitting, values, setFieldValue, setErrors }) => (
                <Form id="form" onSubmit={handleSubmit}>
                    <InputStyled
                        name="files"
                        id="files"
                        accept=".jpg, .jpeg, .png"
                        multiple={true}
                        onChange={e => {
                            const files = Array.from(e.target?.files);
                            files.forEach(file => {
                                const key = file.name.replaceAll(".", "") + file.lastModified;
                                setFieldValue(`files[${key}]`, file);
                            });
                        }}
                    />
                    {!Object.keys(values.files).length && (
                        <ErrorMessage component="p" className="text-red mb-4 font-light text-xs" name={`files`} />
                    )}

                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(values.files).map(([key, file]) => (
                                    <tr key={key}>
                                        <td>
                                            {file.name}
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name={`files[${key}]`}
                                            />
                                        </td>
                                        <td>
                                            <div className="flex items-center">
                                                <button
                                                    className="p-2 rounded-md bg-canal_vermelho"
                                                    type="button"
                                                    onClick={() => removeTempFile({ values, key, setValues, setErrors })}
                                                >
                                                    <FaTrashAlt color="#fff" />
                                                </button>
                                                {percent[key] > 0 && percent[key] < 100 && (
                                                    <>
                                                        <span className="mx-2">{percent[key]}%</span>
                                                        <ClipLoader size={20} color={colors.roxo_oficial} loading={true} />
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <section>
                            <ButtonDefault disabled={isSubmitting} type="submit">
                                {isSubmitting ? (
                                    <div>
                                        <ClipLoader size={20} color={colors.roxo_oficial} loading={true} />
                                    </div>
                                ) : (
                                    "Enviar"
                                )}
                            </ButtonDefault>
                        </section>
                    </>
                </Form>
            )}
        </Formik>
    );
}

import styled, { css } from "styled-components";
import { colors } from "../../assets/colors";

const ButtonDefault = styled.button`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${colors.roxo_oficial};
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0px 13px;
    font-size: 14px;
    font-weight: 550;
    cursor: pointer;

    :disabled {
        cursor: not-allowed !important;
    }

    ${({ height, width }) => css`
        height: ${height || "30px"};
        min-width: ${width || "100px"};
    `}
`;
export default ButtonDefault;

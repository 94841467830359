import styled from "styled-components";

import { colors } from "../../assets/colors";
export default styled.table`
    table-layout: auto;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    text-align: left;
    position: relative;

    thead th {
        width: auto;
        border-bottom: 1px solid ${colors.roxo_oficial};
        color: ${colors.roxo_oficial};
        padding: 5px;
    }
    tbody td {
        padding: 5px;
        color: ${colors.C5};
        text-overflow: ellipsis;
    }
    tbody tr {
        transition: 0.2s;
    }
    tbody tr:nth-child(2n) {
        background-color: #eee;
    }
    tbody tr:hover {
        background-color: #ddd;
    }
    caption {
        font-size: 1.5em;
        margin: 0.5em 0 0.75em;
    }

    @media screen and (max-width: 600px) {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            display: block;
            margin-bottom: 0.625em;
        }

        table td {
            display: block;
            font-size: 0.8em;
        }

        table td::before {
            /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
            content: attr(data-label);
            float: left;
            font-weight: bold;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }
`;

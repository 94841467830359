import React from "react";
import styled from "styled-components";

const Input = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    position: relative;
    border: 1px dashed #dddd;

    label {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        transition: background 0.2s;

        &:hover {
            background: #dcdcdc21;
        }

        input[type="file"]::-webkit-file-upload-button {
            display: none;
        }
        input[type="file"] {
            opacity: 0;
            flex-grow: 2;
            cursor: pointer;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        text-align: center;

        strong {
            font-weight: 300;
            color: #646464;
        }
        span {
            color: #a3a3a3;
        }
    }
`;

export default function InputStyled({ onChange, accept, multiple }) {
    const acceptText = accept
        .split(",")
        .map(d => (d === "image/*" ? "imagens" : d))
        .join(", ");

    return (
        <Input>
            <label htmlFor="upload">
                <input
                    type="file"
                    name="upload"
                    id="upload"
                    accept={accept}
                    onChange={onChange}
                    placeholder="Enviar arquivo"
                    multiple={multiple}
                />
            </label>
            <div>
                <strong>Arraste e solte os arquivos aqui ou clique para selecionar</strong> <br />
                <span>formatos permitidos: {acceptText}</span>
            </div>
        </Input>
    );
}
